
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MovieCard extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private movie!: any
}
