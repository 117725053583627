
import { Component, Prop, Vue } from 'vue-property-decorator'

import UniversalCookie from 'universal-cookie'
import GlobalMenu from '~/components/layouts/header/menu/GlobalMenu.vue'
import authSetting from '~/config/authSetting'
import { API } from '~/types/api'

@Component({
  components: {
    GlobalMenu
  }
})
export default class HeaderNavigationMenu extends Vue {
  @Prop({ type: String, required: true }) private transition!: string

  private headerMenuOpend = false
  private noAnimation = true

  private get auth() {
    return this.$store.state.authentication.membersHeader
  }

  private startAnimation() {
    if (this.noAnimation) this.noAnimation = false
  }

  // ----------- user menu ---------------
  private menu = {
    mypage: [
      {
        name: 'マイページ',
        link: '/members/mypage'
      }
    ],
    settings: [
      {
        name: 'プロフィール',
        param: 'profile'
      },
      {
        name: 'メール設定',
        param: 'notification'
      }
    ],
    other: [
      {
        name: 'よくある質問',
        link: '/faq'
      }
    ]
  }

  private linkToParam(param) {
    const url = `/members/profile?type=${param}`
    this.$router.push(url)
  }

  private handleCloseMenu() {
    this.headerMenuOpend = false
  }

  private async logout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.logoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async logoutAction(csrf: string) {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // API401の際にsetされるcookie削除
          cookies.remove('unauthorized_error')
          // 退会完了時にsetされるcookie削除
          cookies.remove('quitMessage')
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/login?logout=true`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }
}
