
import { Component, Prop, Vue } from 'vue-property-decorator'
import WantedlyIcon from '~/components/shared/icon/WantedlyIcon.vue'

@Component({
  components: {
    WantedlyIcon
  }
})
export default class SnsIconSet extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private urlList: object

  @Prop({
    type: String,
    required: false
  })
  private used: string
}
