
import { Component, Vue } from 'vue-property-decorator'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import Logo from '~/components/shared/Logo.vue'

@Component({
  components: {
    Logo,
    LogoBtn
  }
})
export default class NonMemberModal extends Vue {
  private get modal(): boolean {
    const state = this.$store.state.modal.nonMemberModalState
    if (state) this.setStorage()
    return state
  }

  private set modal(value: boolean) {
    !value
      ? this.modalClose()
      : this.$store.dispatch('modal/changeNonMemberModalState', value)
  }

  private modalClose(): void {
    this.$store.dispatch('modal/changeNonMemberModalState', false)
  }

  private nonMemberModalClose(): void {
    this.removeStorage('beforePath')
    this.removeStorage('twitterLogin')
    this.modalClose()
  }

  private overlayCloseModal(value: boolean): void {
    if (!value) this.nonMemberModalClose()
  }

  private setStorage(): void {
    localStorage.setItem('beforePath', this.$route.fullPath)
  }

  private removeStorage(name: string): void {
    localStorage.removeItem(name)
  }
}
