var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[{ preview: _vm.isPreviewPath() }, 'all_container', 'company-layout']},[(_vm.showLoading)?_c('div',{staticClass:"spinner-area"},[_c('LoadingSpinner',{attrs:{"classname":"dark top","loadingtext":""}})],1):_c('div',{staticClass:"main-container",attrs:{"id":"main-container"}},[(_vm.companyInfo.coverImageUrl)?_c('div',{staticClass:"cover-wrapper"},[_c('div',{staticClass:"cover-image",style:(_vm.companyInfo.coverImageUrl
            ? 'background-image:url(' + _vm.companyInfo.coverImageUrl + ');'
            : '')})]):_vm._e(),_vm._v(" "),_c('Header'),_vm._v(" "),_c('CompanyInfo',{attrs:{"company-data":_vm.companyInfo,"used":"header"}}),_vm._v(" "),_c('v-tabs',{staticClass:"company-tabs",attrs:{"active-class":"active","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.filteredTabs),function(tab,index){return _c('v-tab',{key:index,staticClass:"tab-item",attrs:{"ripple":false}},[_c('nuxt-link',{staticClass:"tab-item-link",attrs:{"to":`/${_vm.$route.params.companyId}/${tab.route}`}},[_vm._v("\n          "+_vm._s(tab.label)+"\n        ")])],1)}),1),_vm._v(" "),_c('v-app',{attrs:{"id":"app"}},[_c('nuxt',{attrs:{"id":"nuxt"}})],1),_vm._v(" "),_c('MessageAfterLogin',{attrs:{"member-id":_vm.memberId}}),_vm._v(" "),_c('CompanyInfo',{attrs:{"company-data":_vm.companyInfo,"used":"footer"}}),_vm._v(" "),_c('Footer'),_vm._v(" "),_c('NonMemberModal'),_vm._v(" "),(_vm.isPreviewPath())?_c('div',{staticClass:"preview-footer"},[_c('div',{staticClass:"preview-button-area"},[_c('p',{staticClass:"preview-title"},[_vm._v("プレビューを選択")]),_vm._v(" "),_vm._l((_vm.previewTabs),function(item){return _c('div',{key:item.label},[_c('div',{class:[
              { disabled: _vm.isDisabled && item.route === '' },
              'preview-button'
            ]},[(!(_vm.isDisabled && item.route === ''))?_c('nuxt-link',{class:[
                { current: _vm.previewCurrentRoute === item.route },
                'preview-link'
              ],attrs:{"to":`/preview/companies/${_vm.$route.params.id}/${item.route}`}},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")]):_c('span',{class:[
                { current: _vm.previewCurrentRoute === item.route },
                'preview-link',
                'disabled'
              ]},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")])],1)])})],2),_vm._v(" "),_c('p',{staticClass:"preview-text"},[_vm._v("プレビューではリンクはクリックできません。")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }