
import { Component, Prop, Vue } from 'vue-property-decorator'

import UniversalCookie from 'universal-cookie'
import authSetting from '~/config/authSetting'
import { API } from '~/types/api'

@Component({
  watch: {
    '$route.path'(newPath, oldPath) {
      this.changePath(newPath, oldPath)
    }
  }
})
export default class HeaderNotification extends Vue {
  @Prop({ type: Number, required: true }) private memberId!: number
  @Prop({ type: String, required: true }) private transition!: string

  private menuActive = false
  private tab = null

  private async changePath(newPath, oldPath) {
    if (newPath !== oldPath) {
      await this.$store.dispatch(
        'notifications/getNotifications',
        this.memberId
      )
    }
  }

  private clickNotification(linkTo) {
    this.menuActive = false
    this.$router.push(linkTo)
  }

  // ----------- tab ---------------
  private defaultTab() {
    // tabの初期位置設定
    this.tab = this.$store.state.notifications.activeTab
    setTimeout(() => this.submitCall(), 500)
  }

  private get notifications() {
    return this.$store.state.notifications.notifications
  }

  private changeTab() {
    this.tab === 0 ? (this.tab = 1) : (this.tab = 0)
    this.submitCall()
  }

  private submitCall() {
    const notices = this.tab === 0 && !this.notifications.notices.confirmed
    const info = this.tab === 1 && !this.notifications.info.confirmed
    if (notices || info) this.submit()
  }

  // ----------- POST ---------------
  private async submit() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.submitAction(token.csrf_token)
        })
    } catch (err) {
      return err
    }
  }

  private async submitAction(csrf: string) {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + `/members/${this.memberId}/notification`
    const postData = {
      notification_type: this.tab
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios.$post(url, postData, config).then(() => {
        this.$store.dispatch(
          'notifications/updateReadAt',
          postData.notification_type
        )
      })
    } catch (err) {
      // APIレスポンスが401だった場合
      const unauthorized =
        err.response.status === 401 || err.response.status === 404
      if (unauthorized) {
        cookies.set('unauthorized_error', true)
        this.errorLogout()
      }
      return err
    }
  }

  private async errorLogout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.errorLogoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async errorLogoutAction(csrf: string) {
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }
}
