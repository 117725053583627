export default async ({ app, store }) => {
  await app.router.afterEach((to, from) => {
    if (
      from.params.companyId &&
      from.params.companyId !== to.params.companyId
    ) {
      store.dispatch('movie/clearMovies')
    }
  })
}
