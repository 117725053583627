
import { Component, Vue } from 'vue-property-decorator'
import Logo from '~/components/shared/Logo.vue'

@Component({
  components: {
    Logo
  }
})
export default class Footer extends Vue {
  private footerData = require('~/assets/json/footer.json')
  private linkMap = require('~/assets/json/links.json')

  // links.jsonを参照しているかどうかの判定
  // key名がpathKeyであれば、link.jsonのから読み込んでいる
  resolvePath(pathKeyOrPath: string): string {
    if (this.linkMap[pathKeyOrPath]) return this.linkMap[pathKeyOrPath]

    return pathKeyOrPath
  }

  // 外部リンクかどうかの判定
  isExternalLink(path: string): boolean {
    const isExternal = /^https?:\/\//.test(path)
    return isExternal
  }
}
