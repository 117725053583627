
import { Component, Prop, Vue } from 'vue-property-decorator'
import MixinActionButtonEvent from '~/components/mixins/MixinActionButtonEvent'
import Follow from '~/components/shared/Follow.vue'

@Component({
  components: {
    Follow
  },
  mixins: [MixinActionButtonEvent]
})
export default class ActionButtonArticle extends Vue {
  @Prop({ required: true, type: Object })
  private company: any

  private following: boolean = false

  showActionBtn(company) {
    const notTrialFlag = company.paid && !company.trial
    return notTrialFlag && company.actionBtns.length > 0
  }
}
