
import { Component, Watch, Vue } from 'vue-property-decorator'
import Header from '~/components/layouts/Header.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import CompanyInfo from '~/components/_companyId/CompanyInfo.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import LoadingSpinner from '~/components/shared/LoadingSpinner.vue'
import { CommonInfo } from '~/types/utils'

@Component({
  components: {
    Header,
    Footer,
    NonMemberModal,
    MessageAfterLogin,
    CompanyInfo,
    LoadingSpinner
  },
  middleware: 'redirectAfterLogin',
  mixins: [MixinMemberId]
})
export default class CompanyLayout extends Vue {
  private showLoading: boolean = true
  private memberId!: number
  private activeTab: number = 0
  private isDisabled = false
  private filteredTabs: Array<{ label: string; route: string }> = []

  private head() {
    return {
      htmlAttrs: {
        class: ['reset-overflow']
      }
    }
  }

  // タブの定義
  private allTabs = [
    { label: 'ホーム', route: '' },
    { label: 'ブック', route: 'books' },
    { label: 'コンテンツ', route: 'contents' },
    { label: '動画', route: 'movies' },
    { label: 'アルバム', route: 'albums' },
    { label: 'メディア/プレス', route: 'media-releases' },
    { label: '会社概要', route: 'company-info' }
  ]

  // storeからcompanyInfoを取得
  private get companyInfo(): CommonInfo | null {
    return this.$store.state.companies.companyInfo || null
  }

  private created() {
    if (process.client) {
      const isDisabled =
        sessionStorage.getItem('previewDisabledButton') === 'true'
      this.isDisabled = isDisabled
    }
    if (this.companyInfo) {
      // filteredTabs の初期設定
      this.filteredTabs = this.allTabs.filter(
        (tab) => tab.route === '' || this.companyInfo?.tabs.includes(tab.route)
      )
      this.showLoading = false
    }
  }

  private setTabs() {
    const responseTabs: string[] = this.companyInfo?.tabs || []
    this.filteredTabs = this.allTabs.filter(
      (tab) => tab.route === '' || responseTabs.includes(tab.route)
    )
    const routeSegment = this.$route.path.split('/')[2] || ''
    const activeTabIndex = this.filteredTabs.findIndex(
      (tab) => tab.route === routeSegment
    )
    this.activeTab = activeTabIndex !== -1 ? activeTabIndex : 0
  }

  // URLが変わるたびにactiveTabを更新
  @Watch('$route.path', { immediate: true })
  onRouteChange(newPath: string) {
    const pathWithoutQuery = newPath.split('?')[0]
    const basePath = this.isPreviewPath()
      ? `/preview/companies/${this.$route.params.id}`
      : `/${this.$route.params.companyId}`
    const routeSegment = pathWithoutQuery.startsWith(basePath)
      ? pathWithoutQuery.slice(basePath.length).replace(/^\//, '')
      : ''

    // 再度フィルタリングを明示的に行う
    this.filteredTabs = this.allTabs.filter(
      (tab) => tab.route === '' || this.companyInfo?.tabs.includes(tab.route)
    )

    const activeTabIndex = this.filteredTabs.findIndex(
      (tab) => tab.route === routeSegment
    )

    this.activeTab = activeTabIndex !== -1 ? activeTabIndex : 0
  }

  private isPreviewPath(): boolean {
    return /^\/preview\//.test(this.$route.path)
  }

  private mounted() {
    // beforePath(ログイン直前path)が残っていればremove
    if (this.getStorage) this.removeStorage('beforePath')
  }

  private get previewTabs() {
    return this.allTabs.filter(
      (tab) => tab.label === 'ホーム' || tab.label === '会社概要'
    )
  }

  private get previewCurrentRoute() {
    const path = this.$route.path
    const basePath = `/preview/companies/${this.$route.params.id}`
    return path === basePath || path === basePath + '/'
      ? ''
      : path.replace(basePath + '/', '')
  }

  private get getStorage(): string {
    return localStorage.getItem('beforePath')
  }

  private removeStorage(name): void {
    localStorage.removeItem(name)
  }
}
