import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { errorHandler } from '~/lib/errorHandling'

export const state = () => ({
  currentCompany: {}, // 現在閲覧中(記事や_companyIdのページ)の企業情報
  companyBookIdArticles: {}, // _companyId/booksのlist
  showLoading: true, // companySearchのloadingSpiner
  noSearchResult: false, // companySearchの検索結果(lengthで表示制御するとタイミング合わないためflag管理)
  searchResults: [],
  searchTotalCount: 0,
  companyInfo: null
})

export const getters = {
  companyInfo: (state) => state.companyInfo
}

export const mutations = {
  SET_CURRENT_COMPANY(state, company) {
    state.currentCompany = company
  },
  SET_CLEAR_CURRENT_CUMPANY(state) {
    state.currentCompany = {}
  },
  SET_SEARCH_COMPANIES_LIST(state, payload) {
    const records = payload.res.records
    const count = payload.res.pageMeta.totalCount
    state.searchTotalCount = count
    payload.add
      ? records.map((x: any) => state.searchResults.push(x))
      : (state.searchResults = records)
  },
  SET_NO_RESULTS_FLAG(state, bool: boolean) {
    state.noSearchResult = bool
    if (bool) state.searchTotalCount = 0
  },
  REMOVE_COMPANIES_LIST(state) {
    state.searchResults = []
  },
  SET_COMPANY_BOOKID(state, res) {
    state.companyBookIdArticles = res
  },
  SET_SEARCH_FLAG(state, bool: boolean) {
    state.showLoading = bool
  },
  SET_COMPANY_INFO(state, data) {
    state.companyInfo = camelcaseKeys(data, { deep: true })
  }
}

export const actions = {
  async getPreviewCompany({ commit }, params: any) {
    const url = process.env.API_URL + `/preview/companies/${params.id}`
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    const company = await this.$axios.$get(url, config)
    commit('SET_CURRENT_COMPANY', camelcaseKeys(company, { deep: true }))
  },
  async getCompany({ state, commit }, params: any) {
    const companyId = params.companyId
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    if (!companyId) {
      commit('SET_CLEAR_CURRENT_CUMPANY')
    } else if (state.currentCompany.id !== companyId) {
      const url = process.env.API_URL + `/companies/${companyId}`
      const company = await this.$axios.$get(url, config)
      company.recommendedStories = company.stories.slice(0, 3)
      company.books.sort((a, b) => a.order_id - b.order_id)
      processAfterAPI(company)
    }
    function processAfterAPI(company: any) {
      commit('SET_CURRENT_COMPANY', camelcaseKeys(company, { deep: true }))
    }
  },
  getCompanyBookId({ commit }, res: any) {
    commit('SET_COMPANY_BOOKID', res)
  },
  // payloadのaddはdefaultでfalse,mutationでpushする場合のみtrue
  async getSearchResults({ commit }, { url, context, add = false }) {
    if (!add) commit('SET_SEARCH_FLAG', true) // loadingSpinerの表示制御
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    await this.$axios
      .$get(url, config)
      .then((res: any) => {
        // 検索結果がない場合は[],ある場合は{}が返る
        if (Array.isArray(res)) {
          // 検索結果がない場合はFlagをtrue
          commit('SET_NO_RESULTS_FLAG', true)
          commit('REMOVE_COMPANIES_LIST')
        } else {
          commit('SET_SEARCH_COMPANIES_LIST', {
            res: camelcaseKeys(res, { deep: true }),
            add
          })
          commit('SET_NO_RESULTS_FLAG', false)
        }
      })
      .then(() => {
        if (!add) commit('SET_SEARCH_FLAG', false) // loadingSpinerの表示制御
      })
      .catch((err) => {
        errorHandler(err, context)
        return err
      })
  },
  async fetchCompanyInfo({ commit }, { companyId, context }) {
    try {
      const isPreview = process.server
        ? /^\/preview\//.test(context.route.path)
        : /^\/preview\//.test(window.location.pathname)
      const baseUrl = isPreview
        ? `/preview/companies/${companyId}/common-info`
        : `/companies/${companyId}/common-info`
      const config = {
        auth: authSetting
      }
      const url = process.env.API_URL + baseUrl

      const response = await this.$axios.$get(url, config)
      commit('SET_COMPANY_INFO', response)
    } catch (err) {
      errorHandler(err, context)
      return err
    }
  }
}
