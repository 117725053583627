
import { Component, Prop, Vue } from 'vue-property-decorator'

import SideNav from '~/components/layouts/SideNav.vue'

@Component({
  components: {
    SideNav
  }
})
export default class HeaderMemberNavigationMenuMobile extends Vue {
  @Prop({ type: String, required: true }) private transition!: string

  private memberMenuOpend = false
  private noAnimation = true

  private startAnimation() {
    if (this.noAnimation) this.noAnimation = false
  }

  private handleCloseMenu() {
    this.memberMenuOpend = false
  }
}
