
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TitleWithViewAll extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private title!: string

  @Prop({
    type: String,
    required: false
  })
  private img: string

  @Prop({
    type: String,
    required: false
  })
  private url: string

  @Prop({
    type: Boolean,
    default: true,
    required: false
  })
  private viewAll: boolean

  @Prop({
    type: String,
    required: false
  })
  private used: string

  // h1~6のtag指定
  @Prop({
    type: Number,
    default: 2,
    required: false
  })
  private level?: number

  private get headingTagLevel() {
    return `h${this.level}`
  }
}
