export function clickItemKarte(trackingData) {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'staging'
  )
    return

  try {
    // eslint-disable-next-line no-undef
    tracker.track('click_item_detail', {
      title: trackingData.title,
      name: trackingData.name,
      url: trackingData.url,
      link: trackingData.link
    })
  } catch (e) {
    if ('tracker' in window) {
      // eslint-disable-next-line no-undef
      tracker.track('_error', { message: e.message })
    }
  }
}
