
import { Component, Vue } from 'vue-property-decorator'
import Header from '~/components/layouts/Header.vue'
import SideNav from '~/components/layouts/SideNav.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'

@Component({
  components: {
    Header,
    SideNav,
    Footer,
    NonMemberModal,
    MessageAfterLogin
  },
  middleware: 'redirectAfterLogin',
  mixins: [MixinMemberId]
})
export default class mypageLayout extends Vue {
  private memberId!: number

  private mounted() {
    const currentPathName = this.$route.name
    // removeDtorageの除外ページ
    // login後のリダイレクトPathが/members/mypageなのでmypageは除外
    const excludeNames = ['members-mypage']
    const twitterLogin = JSON.parse(
      localStorage.getItem('twitterLogin') || 'false'
    )

    // 現在のパスが excludeNames 以外の場合にのみ removeItem を実行
    if (this.getStorage && !excludeNames.includes(currentPathName)) {
      if (twitterLogin) {
        this.removeStorage('twitterLogin')
        this.$router.push(this.getStorage)
      }
      this.removeStorage('beforePath')
      this.removeStorage('twitterLogin')
    }
  }

  private get getStorage(): string {
    return localStorage.getItem('beforePath')
  }

  private removeStorage(name): void {
    localStorage.removeItem(name)
  }
}
