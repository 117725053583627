import { clickItemKarte } from '~/plugins/karte-click-item'
import { Tracking } from '~/types/tracking'

export default {
  methods: {
    sendEvent(i: number) {
      const trackingData: Tracking.FixedKarteTracking = {
        title: this.$store.state.tracking.karteTrackingItems.title,
        name: this.$store.state.tracking.karteTrackingItems.actionBtns[i].name,
        url: this.$store.state.tracking.karteTrackingItems.url,
        link: this.$store.state.tracking.karteTrackingItems.actionBtns[i].url
      }

      clickItemKarte(trackingData)
      window.open(
        this.$store.state.tracking.karteTrackingItems.actionBtns[i].url,
        '_blank'
      )
    },
    url(originalUrl) {
      if (!originalUrl) {
        return ''
        // 相対パスの場合、nuxtのドメインではなく、railsのドメインへとリダイレクトする
        // （フォーム周りの再実装を避けるため、リニューアル後もrailsで配信することにした）
      } else if (originalUrl[0] === '/') {
        return process.env.DASHBOARD_HOST + originalUrl
      } else {
        return originalUrl
      }
    }
  },
  mounted() {
    let entryKarteTracking: Tracking.KarteTracking
    const currentUrl: Tracking.CurrentUrl = {
      url: window.location.href,
      name: this.$route.name
    }

    try {
      entryKarteTracking = {
        companyName: this.company.name,
        actionBtns: this.company.actionBtns
      }
    } catch {
      entryKarteTracking = {
        title: this.article.title,
        companyName: this.article.company.name,
        actionBtns: this.article.actionBtns
      }
    } finally {
      this.$store.dispatch('tracking/setKarteTrackingItem', {
        entryKarteTracking,
        currentUrl
      })
    }
  }
}
