
import { Component, Vue } from 'vue-property-decorator'
import Header from '~/components/layouts/Header.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'

@Component({
  components: {
    Header,
    Footer,
    NonMemberModal,
    MessageAfterLogin
  },
  head: {
    htmlAttrs: {
      class: ['reset-overflow']
    }
  },
  middleware: 'redirectAfterLogin',
  mixins: [MixinMemberId]
})
export default class Blank extends Vue {
  private memberId!: number

  private mounted() {
    const currentPathName = this.$route.name
    // removeDtorageの除外ページ
    const excludeNames = ['talentbook_guide', 'signup']

    // 現在のパスが excludeNames 以外の場合にのみ removeItem を実行
    if (this.getStorage && !excludeNames.includes(currentPathName)) {
      this.removeStorage('beforePath')
      this.removeStorage('twitterLogin')
    }
  }

  private get getStorage(): string {
    return localStorage.getItem('beforePath')
  }

  private removeStorage(name): void {
    localStorage.removeItem(name)
  }
}
