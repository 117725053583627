
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHeader from '~/components/shared/card/CardHeader.vue'
import categoryIcon from '~/components/shared/icon/ArticleCategoryType.vue'
import { Stories } from '~/types/stories'

@Component({
  components: {
    CardHeader,
    categoryIcon
  }
})
export default class ArticleCard extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private article!: Stories.StoryRecords

  @Prop({
    type: Number,
    required: false
  })
  private index: number

  // categoriesからの遷移時にパラメータ付与する
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private paramSrag: string

  @Prop({
    type: Boolean,
    required: false
  })
  private bigCard: boolean

  // cardの利用箇所によってstyle分岐させる
  // TODO: 他のstyleもこの管理にしたい
  @Prop({
    type: String,
    required: false
  })
  private used: string

  // usedがcarouselの場合、extraUsedで固有設定を適用
  @Prop({
    type: String,
    required: false
  })
  private extraUsed: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private recommend!: boolean

  @Prop({
    type: Function,
    required: false
  })
  private onSlideClick?: Function

  @Prop({
    type: Number,
    required: false,
    default: null
  })
  private bookId!: number | null

  private get articleLink() {
    if (this.bookId) {
      // `bookId` を付与
      return `${this.article.path}?bookId=${this.bookId}`
    } else if (this.paramSrag) {
      // `category` を付与
      return `${this.article.path}?category=${this.paramSrag}`
    }
    // 条件に該当しない場合はそのままのパスを返す
    return this.article.path
  }

  private get isSavedBookshelf() {
    return this.used === 'bookshelf' ? true : this.article.isSaved
  }

  handleArticleClick() {
    // Propのスライドクリックイベントをcall
    if (this.onSlideClick) this.onSlideClick()

    // KARTEの計測タグがあり、おすすめ記事の場合はKARTEのイベント発行
    if (window.krt && this.recommend) {
      const from = this.determineFrom()
      const fromUrl = this.$route.fullPath
      const distUrl = this.articleLink
      const memberId = this.$store.state.member.memberFollowing.memberId || ''

      window.krt('send', 'click_recommend_article', {
        from,
        fromUrl,
        distUrl,
        memberId
      })
    }
  }

  // KARTEカスタムイベント用に現在のpathに応じたnameを返却
  determineFrom() {
    const path = this.$route.path

    const routeMap: { [key: string]: (path: string) => boolean } = {
      top: (p) => p === '/',
      mypage: (p) => p.startsWith('/members/mypage'),
      story: (p) => p.includes('/stories/') && !p.endsWith('/stories'),
      knowhow: (p) => p.includes('/knowhows/') && !p.endsWith('/knowhows')
    }

    return Object.keys(routeMap).find((key) => routeMap[key](path)) || 'unknown'
  }

  private get shouldDisplayFooter() {
    // 企業ページから利用された場合はcompany.nameを非表示
    // usedがbig-car,company / extraUsedがcompanyの場合はfalse
    return (
      !['big-card'].includes(this.used) &&
      !['company'].includes(this.extraUsed) &&
      !['company'].includes(this.used)
    )
  }
}
