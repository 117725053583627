
import { Component, Prop, Vue } from 'vue-property-decorator'
import ActionButtonCompany from '~/components/shared/ActionButtonCompany.vue'
import Follow from '~/components/shared/Follow.vue'
import SnsIconSet from '~/components/_companyId/SnsIconSet.vue'

@Component({
  components: {
    ActionButtonCompany,
    Follow,
    SnsIconSet
  }
})
export default class CompanyInfo extends Vue {
  @Prop({ required: true, type: String })
  private used: string

  @Prop({ required: true, type: Object })
  private companyData: any

  private get isHeader() {
    return this.used === 'header'
  }

  private get isFooter() {
    return this.used === 'footer'
  }

  private get shreUrlList() {
    return {
      facebook: this.companyData.facebook,
      twitter: this.companyData.twitter,
      youtube: this.companyData.youtube,
      instagram: this.companyData.instagram,
      wantedly: this.companyData.wantedly,
      linkedin: this.companyData.linkedin,
      tiktok: this.companyData.tiktok
    }
  }

  private get categoryText(): string {
    const { categoryName, subCategoryName } = this.companyData
    // どちらかの値がない場合は片方のみ返す
    return categoryName && subCategoryName
      ? `${categoryName} > ${subCategoryName}`
      : categoryName || subCategoryName || ''
  }
}
