import Cookies from 'universal-cookie'
import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { Bookshelf } from '~/types/bookshelf'
import { API } from '~/types/api'

export const state = () => ({
  isSaved: false,
  compliteIsSaved: false
})

export const mutations = {
  SET_STORY_IS_SAVED_CHENGED(state, res: boolean) {
    state.isSaved = res
  },
  SET_IS_SAVED(state, res: boolean) {
    state.isSaved = res
  },
  SET_COMPLITE_IS_SAVED(state, res: boolean) {
    state.compliteIsSaved = res
  }
}

export const actions = {
  changeStoryIsSaved({ commit }, payload: boolean) {
    commit('SET_STORY_IS_SAVED_CHENGED', payload)
  },
  backToDefaultIsSavedFlag({ commit }, stateBool: boolean) {
    commit('SET_COMPLITE_IS_SAVED', stateBool)
  },
  async getKnowhowIsSaved({ commit }, params: Bookshelf.GetStoryParams) {
    const cookies = new Cookies()
    const knowhowUrl =
      process.env.API_URL +
      `/knowhows/${params.id}?companyId=${params.companyId}`
    await this.$axios
      .$get(knowhowUrl, {
        auth: authSetting,
        withCredentials: true
      })
      .then((res) => {
        commit('SET_IS_SAVED', camelcaseKeys(res.is_saved, { deep: true }))
        commit('SET_COMPLITE_IS_SAVED', true)
      })
      .catch((err) => {
        // APIレスポンスが401だった場合
        const unauthorized =
          err.response.status === 401 || err.response.status === 404
        if (unauthorized) {
          cookies.set('unauthorized_error', true)
          logout()
        }
      })
    async function logout() {
      const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: API.CSRF) => {
            logoutAction(token.csrf_token)
          })
      } catch (e) {
        return e
      }
    }

    async function logoutAction(csrf: string) {
      const url = process.env.API_URL + '/members/logout'
      const config = {
        headers: {
          'X-CSRF-Token': csrf
        },
        auth: authSetting,
        withCredentials: true
      }
      try {
        await this.$axios
          .$patch(url, {}, config)
          .then(() => {
            // sessionStorageのmemberId削除
            sessionStorage.removeItem('member')
          })
          .then(() => {
            const redirectUrl = `/`
            location.href = redirectUrl
          })
      } catch (e) {
        return e
      }
    }
  }
}
