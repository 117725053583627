const middleware = {}

middleware['checkCookie'] = require('../middleware/checkCookie.ts')
middleware['checkCookie'] = middleware['checkCookie'].default || middleware['checkCookie']

middleware['companyCommonInfo'] = require('../middleware/companyCommonInfo.ts')
middleware['companyCommonInfo'] = middleware['companyCommonInfo'].default || middleware['companyCommonInfo']

middleware['redirectAfterLogin'] = require('../middleware/redirectAfterLogin.ts')
middleware['redirectAfterLogin'] = middleware['redirectAfterLogin'].default || middleware['redirectAfterLogin']

export default middleware
